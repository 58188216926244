import React from "react";
import { Layout } from "../../layouts";
import { graphql, Link } from "gatsby";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import styled from "styled-components";
import { below, elevation } from "../../utilities";

const AboutContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const AboutContentSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 24px;
  width: 60%;
  p {
    margin-bottom: 1.2rem;
  }
  :last-child {
    padding-bottom: 0;
  }
  ${below.medium`
    width: 100%;
  `}
`;

const HeaderSectionBorder = styled.div`
  height: 650px;
  max-height: 650px;
  width: 100%;

  ${below.medium`
    height: 375px;
  `};
`;

const HeaderSectionWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  height: 650px;
  max-height: 650px;
  width: 100%;

  ${below.medium`
    height: 250px;
  `};
`;

const HeaderImg = styled(GatsbyImage)<GatsbyImageProps>`
  height: 100%;
`;

const ArticleImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 50%;
  border-radius: 8px;
  margin: 8px 0;
  ${elevation[3]};
  ${below.medium`
    width: 85%;
  `}
`;

const HeaderSectionContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 8px 96px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${below.medium`
    top: 64px;
    justify-content: center;
    padding: 64px 8px;
  `};
`;

const HeaderTitleSection = styled.div`
  width: 40%;
  max-width: 500px;
  background: ${(props) => props.theme.colors.background.secondary60};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    margin-bottom: 1.2rem;
  }

  ${below.medium`
    width: 100%;
    max-width: 90%;
    margin-top: 64px
  `};
`;

export const fullWidthTracedImage = graphql`
  fragment fullWidthTracedImage on File {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
`;

export const query = graphql`
  query ImageQuery {
    strat: file(relativePath: { eq: "StrummingStrat.jpeg" }) {
      ...fullWidthTracedImage
    }
    gc: file(relativePath: { eq: "GrandCanyonCold.jpeg" }) {
      ...fullWidthTracedImage
    }
  }
`;

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <HeaderSectionBorder>
        <HeaderSectionWrapper>
          <HeaderImg
            image={data.gc.childImageSharp.gatsbyImageData}
            // image={data.gc.childImageSharp.gatsbyImageData}
            alt={"Me at the grand canyon"}
            loading={"eager"}
          />
          <HeaderSectionContainer>
            <HeaderTitleSection>
              <h1>The About Page</h1>
              <p>Everything you never wanted to know about me, and more.</p>
              <p>
                Like, here I am at the Grand Canyon on a freezing cold morning
                in late April, 2018.
              </p>
            </HeaderTitleSection>
          </HeaderSectionContainer>
        </HeaderSectionWrapper>
      </HeaderSectionBorder>
      <AboutContentWrapper>
        <AboutContentSection id="intro">
          <h2>Hi, I'm Carl</h2>
          <p>
            If you're really interested in my background and what makes me get
            out of bed in the morning, this is the page for you. Keep reading
            for all sorts of not very interesting information. Or better yet,
            check out my <Link to="/blog">blog</Link> for more interesting
            content.
          </p>
          <ArticleImage
            image={data.strat.childImageSharp.gatsbyImageData}
            alt={"This is me playing my American Ultra strat."}
            loading={"lazy"}
          />
        </AboutContentSection>
        <AboutContentSection id="job">
          <h2>My Job</h2>
          <p>
            I'm a software engineering manager at Bentley Systems, where I work
            with teams who are leading the company's new iTwin Platform
            division.
          </p>
          <p>
            I started working here in 2011, right out of college, as an entry
            level software engineer. Over the years I've worked on desktop
            applications built in C# (.Net 2), mobile applications built in
            JavaScript, then TypeScript. In 2018 I started working on one of
            Bentley's first 100% cloud native applications.
          </p>
        </AboutContentSection>
        <AboutContentSection id="running">
          <h2>Running</h2>
          <p>
            I was a pretty heavy smoker when I was younger. For almost 15 years
            I smoked over a pack a day. Even when I was in the Navy. In those
            days I didn't run one step further than I absolutely needed to,
            really just enough to scrape by my fitness tests (I was a proud
            member of the 3-mile-a-year club). And once I got out of the Navy, I
            stopped doing even that.{" "}
          </p>
          <p>
            When I was 31, I decided that I'd had enough. One day I just put out
            a cigarette, thew out the rest of the pack, and quit cold turkey. I
            didn't really think it'd work (I'd tried the patch, gum, pills, you
            name it), but somehow this time it stuck. I completely stopped
            smoking and I haven't touched a cigarette in over ten years. What I
            didn't stop doing was eating. I ate everything, and I ate a lot of
            it. By the time I was 32, it had caught up to me.
          </p>
          <p>
            In the spring of 2012, I had a wake up call when I realized that I
            was out of breath after putting on my socks. For some reason, I
            decided that I'd had enough (never mind that I was frequently sick,
            and even when I wasn't actually sick I didn't feel good). Somehow
            I'd let myself get to 248 pounds.
          </p>
          <p>
            That was nearly 100 pounds more than when I was 22. I couldn't
            believe it. So I spent a few minutes researching the best app for
            beginning runners, and I quickly found a Couch25K app, downloaded
            that, and to my own surprise I actually stuck with it. Like
            everyone, at first I hated running, but by the time I made it to
            Week 5 (the make-or-break for a lot of people, it's the first time
            you run for 20 minutes straight) I was starting to enjoy myself.
          </p>
          <p>
            What had started as a desperate attempt to save myself eventually
            became an essential part of my life. I finished the 5k program, then
            the 10k program, and by 2015 I was able to run a half-marathon
            without dying, even though that first half distance knocked me out
            for months. Today I run 6 days a week, year round (thanks to
            NordicTrack and iFit!), and I often run 14 or 15 miles on a Saturday
            morning, just for fun.
          </p>
        </AboutContentSection>
        <AboutContentSection id="guitar">
          <h2>Guitar</h2>
          <p>
            I was really lucky that I grew up during the time that the best
            music ever made was regularly played on the radio. Classic rock was
            Led Zepplin and Pink Floyd, alternative was Nirvana and Pearl Jam,
            and hard rock and metal meant Metallica, Guns 'n Roses, and
            Megadeth.
          </p>
          <p>
            So of course I wanted a guitar. When I was 14, my dad got me a
            really cheap Memphis strat copy. The action was impossibly high, it
            wouldn't stay in tune, and it shocked me when the whammy bar was
            connected, but I loved it. I played all the time, mostly teaching
            myself how to play Nirvana and Alice In Chains songs. I stuck with
            it through high school, but when I joined the Navy I had to leave
            that behind. It wasn't until my last year in that I bought a
            Takamine acoustic at a pawn shop in San Diego and I started playing
            again pretty regularly.
          </p>
        </AboutContentSection>
      </AboutContentWrapper>
    </Layout>
  );
};

export default AboutPage;
